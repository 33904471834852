<!--学生管理-成绩分析-->
<template>
  <div class="analysis" v-if="totalCont">
    <div class="title">{{ totalCont.exam_info.student_name }}的个人成绩分析</div>
    <div class="info">
      <div class="t-item">
        <div class="t-key">考试名称</div>
        <div class="t-val">{{ totalCont.exam_info.exam_name }}</div>
      </div>
      <div class="t-item">
        <div class="t-key">考试类型</div>
        <div class="t-val">{{ totalCont.exam_info.exam_type_name }}</div>
      </div>
      <div class="t-item">
        <div class="t-key">考试开始时间</div>
        <div class="t-val">{{ totalCont.exam_info.exam_start_at }}</div>
      </div>
      <div class="t-item">
        <div class="t-key">考试结束时间</div>
        <div class="t-val">{{ totalCont.exam_info.exam_end_at }}</div>
      </div>
    </div>
    <div class="general">
      <div class="box" style="background-color: #799CF8;">
        <div class="text">总成绩</div>
        <div class="grade">{{ totalCont.total_info.exam_total_score }}</div>
      </div>
      <div class="box" style="background-color: #AF9EF7;">
        <div class="text">年级排名</div>
        <div class="grade">{{ totalCont.total_info.exam_grade_rank }}</div>
      </div>
      <div class="box" style="background-color: #FD92F1;">
        <div class="text">行政班排名</div>
        <div class="grade">{{ totalCont.total_info.exam_class_rank }}</div>
      </div>
      <div class="box" style="background-color: #FF95A5;">
        <div class="text">年级平均分差距</div>
        <div class="grade">{{ totalCont.total_info.exam_average_diff | toFixedOne }}</div>
      </div>
      <div class="box" style="background-color: #EFAE8E;">
        <div class="text">年级中位数差距</div>
        <div class="grade">{{ totalCont.total_info.exam_median_diff | toFixedOne }}</div>
      </div>
      <div class="box" style="background-color: #FCC67B;">
        <div class="text">本科线差距</div>
        <div class="grade">{{ totalCont.total_info.exam_benco_diff | toFixedOne }}</div>
      </div>
      <div class="box" style="background-color: #91E0A1;">
        <div class="text">一本线差距</div>
        <div class="grade">{{ totalCont.total_info.exam_yiben_diff | toFixedOne }}</div>
      </div>
    </div>
    <div class="subjects">
      <div class="subject" :class="{'active':subjectId==item.id}" v-for="item in subjects" @click="subjectChange(item.id)">
        {{ item.subject_name }}
      </div>
    </div>
    <div class="subjectDetails">
      <div class="grade-analysis">
        <div class="title">
          <span>单科分数</span>
          <span>学科得分率</span>
          <span>年级排名</span>
          <span>教学班排名</span>
          <span>年级平均分差距</span>
          <span>年级中位数差距</span>
          <span>本科线差距</span>
          <span>一本线差距</span>
        </div>
        <div class="grade">
          <span>{{ subjectCont.exam_score }}</span>
          <span>{{ subjectCont.exam_score_rate }}</span>
          <span>{{ subjectCont.exam_grade_rank }}</span>
          <span>{{ subjectCont.exam_teaching_rank }}</span>
          <span>{{ subjectCont.exam_average_diff | toFixedOne }}</span>
          <span>{{ subjectCont.exam_median_diff | toFixedOne }}</span>
          <span>{{ subjectCont.exam_benco_diff | toFixedOne }}</span>
          <span>{{ subjectCont.exam_yiben_diff | toFixedOne }}</span>
        </div>
      </div>
      <!--   知识点   -->
      <div class="ability">
        <div class="title">
          <b>知识点掌握情况</b>
        </div>
        <list-template
            :total="total"
            :table-config="abilityConfig"
            :current-page="page"
            :table-data="abilityData"
            @onChangePage="changeCurrentPage"
            style="width: 100%"
            :page-size="15"
        >
        </list-template>
      </div>
    </div>
    <div class="footer-button">
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"achievement-analysis","meta":{"title":"个人成绩分析","style":1}}},
  name: "achievement-analysis",
  data() {
    return {
      student_no: '',
      exam_id: '',
      totalCont: '',

      subjects: [],
      subjectId: '',
      subjectCont: '',

      gradeAnalysis: [],
      abilityConfig: [
        {
          label: "掌握程度",
          prop: "understand_level",
          width: 100
        },
        {
          label: "知识点内容",
          prop: "ability_name",
        },
        {
          label: "知识点得分率",
          prop: "score_rate",
          width: 118
        },
        {
          label: "涉及题目及得分率",
          prop: "serialnum_score",
          width: 190
        }
      ],
      abilityData: [],
      page: 1,
      total: null,
    }
  },
  created() {
    this.student_no = this.$route.query.student_no || '';
    this.exam_id = this.$route.query.exam_id || ''
    this.subjectId = this.$route.query.subject_id || ''
    this.getData();

    this.getSubject()

  },
  methods: {
    getData() {
      this.$_axios.post("/exam-analysis/by-subject-total", {
        exam_id: this.exam_id,
        student_no: this.student_no
      }).then(res => {

        this.totalCont = res.data.data
        this.totalCont.total_info.exam_average_diff = Number(this.totalCont.total_info.exam_average_diff).toFixed(1)
      })
    },

    getSubject() {
      this.$_axios.get('/site/exam-subject', {
        params: {
          exam_id: this.exam_id,
          subject: 1
        }
      }).then(res => {
        if (res.data.data) {
          this.subjects = JSON.parse(JSON.stringify(res.data.data).replace(/child/g, 'children'))
          this.subjectId = this.subjectId || this.subjects[0].id
          this.subjectChange(this.subjectId)
        }
      })
    },

    subjectChange(id) {
      this.subjectId = id
      this.getKnowledge()
      this.$_axios.post('/exam-analysis/by-single-subject', {
        exam_id: this.exam_id,
        student_no: this.student_no,
        subject_id: id
      }).then(res => {
        if (res.data.data.constructor === Object) {
          this.subjectCont = res.data.data
        } else {
          Object.keys(this.subjectCont).forEach(key => {
            this.subjectCont[key] = ''
          })
        }
      })
    },

    getKnowledge() {
      this.$_axios.post('/exam-analysis/by-knowledge-hold', {
        exam_id: this.exam_id,
        student_no: this.student_no,
        subject_id: this.subjectId,
        page: this.page
      }).then(res => {
        this.abilityData = res.data.data
        let {total} = this.$tools.getPaginationInfo(res.headers);
        this.total = total;
      })
    },

    changeCurrentPage(e) {
      this.page = e;
      this.getKnowledge();
    },
  }

}
</script>

<style scoped lang="scss">
.analysis {
  padding: 65rem 50rem 0;

  > .title {
    font-size: 20rem;
    font-weight: bold;
    margin-bottom: 20rem;
  }

  // 本次考试信息
  .info {
    background-color: rgba(234, 234, 234, 0.25);
    font-size: 14rem;
  }

  // 本次考试的总体情况
  .general {
    display: flex;
    justify-content: space-between;
    text-align: center;

    .box {
      border-radius: 10rem;
      color: white;
      margin-top: 32rem;

      .text {
        padding-top: 13rem;
        font-size: 16rem;
        font-weight: 600;
      }

      .grade {
        font-size: 32rem;
        margin-top: 30rem;
      }
    }
  }

  // 科目选项
  .subjects {
    display: grid;
    grid-template-columns: repeat(6, 16.66666%);
    grid-template-rows: 32rem;
    line-height: 32rem;
    margin-top: 53rem;
    text-align: center;
    background-color: #FAFAFA;
    color: #666;

    .subject {
      cursor: pointer;
      border-right: 1rem solid #EAEAEA;
    }

    .subject.active {
      background-color: #1C4EFD;
      color: white;
    }

    .subject:last-child {
      border-right: none;
    }
  }

  // 科目下的详细信息
  .subjectDetails {
    padding: 32rem 30rem 42rem;
    border: 1rem solid #eee;

    .grade-analysis {
      text-align: center;

      .title {
        background-color: #E8EDFF;
        height: 36rem;
        font-size: 14rem;
        font-weight: 600;
        color: #333333;
        line-height: 36rem;
        display: grid;
        grid-template-columns: repeat(8, 12.5%);
      }

      .grade {
        display: grid;
        grid-template-columns: repeat(8, 12.5%);
        line-height: 96rem;
        height: 96rem;
        border-bottom: 1rem solid #eeeeee;
        border-left: 1rem solid #eeeeee;
        color: #1C4EFD;
        font-size: 28rem;
        font-weight: bold;

        span {
          display: block;
          border-right: 1rem solid #eeeeee;
        }
      }
    }

    .ability {
      margin: 24rem 0;

      .title {
        font-size: 16rem;
      }
    }
  }

  @media only screen and (min-width: 1300rem) {
    .info {
      display: flex;
      justify-content: space-around;
      padding: 25rem 20rem;
    }
    .general {
      .box {
        width: 140rem;
        height: 132rem;
      }
    }
  }
  @media only screen and (max-width: 1299rem) {
    .info {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      padding: 10rem 20rem 25rem;

      .t-item {
        margin-top: 15rem;
      }
    }
    .general {
      .box {
        width: 110rem;
        height: 100rem;

        .text {
          font-size: 14rem;
        }

        .grade {
          margin-top: 20rem;
          font-size: 28rem;
        }
      }
    }
    .subjectDetails .grade-analysis {
      .title {
        font-size: 12rem;


      }
    }
  }
}


@media screen and (max-width: 1420rem) {
  .analysis .subjectDetails .grade-analysis .grade span {
    font-size: 20rem
  }
}
</style>
